import { APISettings } from '@/config';
import axios from 'axios';

export default {
    location_endpoint: '/reservations/location',

    config: {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=utf-8',
        }
    },

    async createReservationLocation(location, access_token) {
        this.config.headers['Authorization'] = 'Bearer ' + access_token
        return axios
            .post(APISettings.baseURL + this.location_endpoint, location, this.config)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.error(error)
                throw error;
            })
    }

}