<template>
  <div class="grid gap-6 w-full">
    <h2 class="p-4 text-3xl font-bold font-poppins underline">{{$t('SubscriptionTitle')}}</h2>
    <div class="grid grid-cols-2 gap-6 w-full">
      <SubscriptionCard v-for="subscription_registration in subscriptions" :key="subscription_registration.id" :subscription="subscription_registration" @selected="onSelect"/>
    </div>
  </div>
</template>

<script>
import SubscriptionMethodsAPI from '@/api/subscription_registration/SubscriptionMethods';
import { ref } from 'vue';
import { mapState } from 'vuex';
import SubscriptionCard from './SubscriptionCard.vue';

export default {
    data() {
        return {
            fetchError: "",
        };
    },
    computed: mapState({
        selectedSubscription: state => state.subscriptions.selectedSubscription,
    }),
    setup() {
        const subscription = ref({});
        const subscriptions = ref([]);
        const loadSubscriptions = async () => {
            subscriptions.value = await SubscriptionMethodsAPI.getByType("HUMBEE");
        };
        return {
            subscription,
            subscriptions,
            loadSubscriptions
        };
    },
    methods: {
      onSelect(subscription){
        this.$store.commit('selectedSubscription', subscription)
      }
    },
    mounted() {
        this.loadSubscriptions();
    },
    components: { SubscriptionCard }
}
</script>

<style>

</style>