<template>
  <Wizard class="w-full" squared-tabs navigable-tabs scrollable-tabs :verticalTabs="verticalTabsActive" 
  :nextButton="{
      text: $t('next'),
      icon: 'back',
      hideIcon: false, // default false but selected for sample
      hideText: false, // default false but selected for sample
  }" 
  :doneButton="{
      text: $t('done'),
      icon: 'back',
      hideIcon: false, // default false but selected for sample
      hideText: false, // default false but selected for sample
  }"
  :backButton="{
      text: $t('back'),
      icon: 'back',
      hideIcon: false, // default false but selected for sample
      hideText: false, // default false but selected for sample
  }"
  :custom-tabs="[
      {
      title: '',
      },
      {
      title: '',
      },
      {
      title: '',
      },
      {
      title: '',
      },
  ]" :beforeChange="onTabBeforeChange" @change="onChangeCurrentTab" @complete:wizard="wizardCompleted">
    <RegisterMerchant  v-if="currentTabIndex === 0" />
    <CompanyInfo v-if="currentTabIndex === 1" />
    <ContactInformation v-if="currentTabIndex === 2" />
    <SubScriptionForm v-if="currentTabIndex === 3" />
  </Wizard>
</template>

<script>
/* eslint-disable */
import Wizard from 'form-wizard-vue3'
// import 'form-wizard-vue3/dist/form-wizard-vue3.css'

import RegisterMerchant from './RegisterMerchant.vue'
import ContactInformation from './ContactInformation.vue'
import CompanyInfo from './CompanyInfo.vue'
import SubScriptionForm from './SubScriptionForm.vue'

//Api's
// import SubscriptionMethodsAPI from '@/api/subscription_registration/SubscriptionMethods';
import RegistrationMethodsAPI from '@/api/subscription_registration/RegistrationMethods';
import ReservationLocationMethodsAPI from '@/api/reservations/LocationMethods';

export default {
  inject: ['keycloak'],
  components: {
    Wizard,
    RegisterMerchant,
    ContactInformation,
    CompanyInfo,
    SubScriptionForm
  },
  mounted() {
      window.addEventListener('resize', this.onResize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {

    onResize() {
      this.verticalTabsActive = window.innerWidth > 720
    }
  ,
  onChangeCurrentTab(index, oldIndex) {
    if (index !== oldIndex) {
      this.currentTabIndex = index;
    }
  },
  onTabBeforeChange() {
  },
  async wizardCompleted() {
    let accountInput = this.$store.state.account
    let addressInput = this.$store.state.address
    let companyInput = this.$store.state.company
    let subscriptionInput = this.$store.state.subscription

    let user = {
      username: accountInput.username,
      password: accountInput.password,
      first_name: accountInput.firstname,
      last_name: accountInput.lastname,
      email: accountInput.username,
      app_user: {
        first_name: accountInput.firstname,
        last_name: accountInput.lastname,
        email: accountInput.username
      }
    };
    console.log(subscriptionInput)
    // Checks before we continue
    if (subscriptionInput == undefined){
      console.warn("Oeps. you didn't select a subscription yet")
      alert('Oeps. you didn\'t select a subscription yet')
      return
    }

    this.$isLoading(true) // show loading screen
    try {
      user = await RegistrationMethodsAPI.createMerchant(user, 'HUMBEE', this.keycloak.token);
    } catch (error) {
      console.error(error)
      if (error.status == 409) {
        alert('Oeps. User account ' + accountInput.username + ' already exists')
      }
      this.$isLoading(false)
      throw error
      // <t-alert variant="error" show>
      //     Something goes wrong
      // </t-alert>
    }

    let registration = {
      user_id: user.id,
      name: companyInput['name'],
      coc: companyInput.coc,
      website_url: companyInput.website_url,
      country_code: companyInput.country_code,
      subscriptions: [subscriptionInput.id],
      person: {
        first_name: user.app_user.first_name,
        last_name: user.app_user.last_name
      },
      addresses: [addressInput],
      emails: [{
        type: "info",
        email: user.app_user.email
      }]
    }
    console.log(JSON.stringify(registration))
    try {
      let registration_result = await RegistrationMethodsAPI.createRegistration(registration, this.keycloak.token);
      location = {
        'administration_id': registration_result.administration_id,
        'id': registration_result.location_id,
        'application': 'HUMBEE',
        'timezone': 'Europe/Amsterdam'
      }
      await ReservationLocationMethodsAPI.createReservationLocation(location, this.keycloak.token) // Create Reservation location
      // 'actaconnectproductie'
      // 'actaconnecttest'
      window.location.href = process.env.VUE_APP_REDIRECT
    } catch (error) {
      console.error(error)
      throw error
    } finally {
      this.$isLoading(false) // show loading screen
    }
    this.$isLoading(false)
  }
},
data() {
  return {
    currentTabIndex: 0,
    access_token: this.keycloak.token,
    verticalTabsActive: window.innerWidth > 720,
  };
},
}

</script>

<style>

</style>